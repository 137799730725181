//基础 常量 此处不常变动
export const CommonConstants = {
    //后台接口baseUrl常量 勿动
    BASE_URL: 'https://xsj.xworld.plus',
    //H5页面服务地址 勿动
    BASE_H5_PAGE_URL: 'https://xsj.shangans.online',
    //shop 分组
    SHOP_GROUP: 'meta://sa?group=shop',
    //common 分组
    COMMON_GROUP: 'meta://sa?group=common',
    //mine 分组
    MINE_GROUP: 'meta://sa?group=mine',
    //play 分组
    PLAY_GROUP: 'meta://sa?group=play',
    //app 分组
    APP_GROUP: 'meta://sa?group=app',
    //vue的路由里面的#号 history 不带#号
    APP_ROUTER_PATH_TYPE:'history',
}

import Vue from 'vue'
import vuex from 'vuex'

Vue.use(vuex)

const store = new vuex.Store({
  // module:{

  // }
  state: { //唯一数据源，我理解为声明全局变量
    userInfo: {
      userMobile:"",
      accessToken:"",
    }, // 用户信息
  },
  getters: { //针对state数据的过滤，

  },
  mutations: { //逻辑处理，但Mutation 必须是同步函数
    setUserInfo(state, value) {
      state.userInfo = value;
    },
  },
  actions: { //Action 类似于 mutation,Action 提交的是 mutation，而不是直接变更状态;Action 可以包含任意异步操作.

  }
})

export default store

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store.js';
import './common/css/common.css';
import './common/css/commonBase750.css';
//toast 提示配置开始
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
//toast 提示配置结束

//https://zhuanlan.zhihu.com/p/359626196
// You are running Vue in development mode.
//Make sure to turn on production mode when deploying for production.
//See more tips at https://vuejs.org/guide/deployment.html
//productionTip设置为 false ，可以阻止 vue 在启动时生成生产提示
Vue.config.productionTip = false


//各种错误拦截
//https://blog.fundebug.com/2019/06/17/handling-errors-in-vuejs/
// Vue.config.errorHandler = function(err, vm, info) {
//   console.log(`Error: ${err.toString()}\nInfo: ${info}`);
// };

// Vue.config.warnHandler = function(msg, vm, trace) {
//   console.log(`Warn: ${msg}\nTrace: ${trace}`);
// };

// window.onerror = function(message, source, line, column, error) {
//   console.log(`window.onerror: ${message}\nsource: ${source}\nline:${line}\ncolumn${column}\nerror:${error}`);
// };

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  watch: {
    '$route'(to) {
      console.log(to.path)
    }
  }
}
</script>

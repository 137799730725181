import Vue from 'vue'
import VueRouter from 'vue-router'
import {CommonConstants} from "../config/common_constants";

Vue.use(VueRouter)

const routes = [
    // {
    //     path: "/mainPage",
    //     component: resolve => require(['@/pages/mainPage.vue'], resolve),
    //     children: [
    //         {
    //             path: "/homePage",
    //             component: resolve => require(['@/pages/app/home/homePage.vue'], resolve),
    //         }, {
    //             path: "/minePage",
    //             component: resolve => require(['@/pages/app/mine/minePage.vue'], resolve),
    //         },
    //     ]
    // },
    //首页直接用根路径
    {
        path: "/",
        component: resolve => require(['@/pages/app/home/firstPage.vue'], resolve),
    },{
        path: "/homePage",
        component: resolve => require(['@/pages/app/home/homePage.vue'], resolve),
    }, {
        path: "/powerIncomeDetail",
        component: resolve => require(['@/pages/app/power/powerIncomeDetail.vue'], resolve),
    }, {
        path: "/getPower",
        component: resolve => require(['@/pages/app/power/getPower.vue'], resolve),
    }, {
        path: "/getPowerDetail",
        component: resolve => require(['@/pages/app/power/getPowerDetail.vue'], resolve),
    }, {
        path: "/topUpPower",
        component: resolve => require(['@/pages/app/power/topUpPower.vue'], resolve),
    }, {
        path: "/topUpPowerRecord",
        component: resolve => require(['@/pages/app/power/topUpPowerRecord.vue'], resolve),
    }, {
        path: "/extract",
        component: resolve => require(['@/pages/app/power/extract.vue'], resolve),
    }, {
        path: "/extractRecord",
        component: resolve => require(['@/pages/app/power/extractRecord.vue'], resolve),
    }, {
        path: "/HelloWorld",
        component: resolve => require(['@/pages/HelloWorld.vue'], resolve),
    }, {
        path: "/back",
        component: resolve => require(['@/pages/back.vue'], resolve),
    }, {
        path: "/storeCoin",
        component: resolve => require(['@/pages/app/power/storeCoin.vue'], resolve),
    }, {
        path: "/storeCoinRecord",
        component: resolve => require(['@/pages/app/power/storeCoinRecord.vue'], resolve),
    },
    //塞红包
    {
        path: '/stuffedRedPack',
        component: resolve => require(['@/pages/app/redPack/index.vue'], resolve),
        meta: {
            title: ""
        }
    }
    //抢红包
    , {
        path: '/gradRedPack',
        component: resolve => require(['@/pages/app/redPack/gradRedPack.vue'], resolve),
        meta: {
            title: ""
        }
    },
    //针对短链
    {
        path: '/g',
        component: resolve => require(['@/pages/app/redPack/gradRedPack.vue'], resolve),
        meta: {
            title: ""
        }
    },
    //红包记录
    {
        path: '/redPackRecord',
        component: resolve => require(['@/pages/app/redPack/redPackRecord.vue'], resolve),
        meta: {
            title: ""
        }
    },
    //红包记录详情
    {
        path: '/projectRedPackRecordDetail',
        component: resolve => require(['@/pages/app/redPack/projectRedPackRecordDetail.vue'], resolve),
        meta: {
            title: ""
        }
    },{
        path: '/projectRedPackUserRecordDetail',
        component: resolve => require(['@/pages/app/redPack/projectRedPackUserRecordDetail.vue'], resolve),
        meta: {
            title: ""
        }
    },
    {
        path: "/walletExtract",
        component: resolve => require(['@/pages/app/wallet/walletExtract.vue'], resolve),
        meta: {
            title: ""
        }
    }, {
        path: "/walletExtractRecord",
        component: resolve => require(['@/pages/app/wallet/walletExtractRecord.vue'], resolve),
        meta: {
            title: ""
        }
    }, {
        path: "/redPackList",
        component: resolve => require(['@/pages/app/redPack/redPackList.vue'], resolve),
        meta: {
            title: ""
        }
    }, {
        path: "/rocketValue",
        component: resolve => require(['@/pages/app/mine/rocketValue.vue'], resolve),
        meta: {
            title: ""
        }
    }, {
        path: "/accountSetting",
        component: resolve => require(['@/pages/app/home/accountSetting.vue'], resolve),
        meta: {
            title: ""
        }
    }, {
        path: "/transferToCoin",
        component: resolve => require(['@/pages/app/wallet/transferToCoin.vue'], resolve),
        meta: {
            title: ""
        }
    }, {
        path: "/carveUpNewB",
        component: resolve => require(['@/pages/app/new/carveUpNewB.vue'], resolve),
        meta: {
            title: ""
        }
    }, {
        path: "/myCoinRecord",
        component: resolve => require(['@/pages/app/wallet/myCoinRecord.vue'], resolve),
        meta: {
            title: ""
        }
    }, {
        path: "/debugPage",
        component: resolve => require(['@/pages/app/home/debugPage.vue'], resolve),
        meta: {
            title: ""
        }
    }, {
        path: "/walletExtractHasNewB",
        component: resolve => require(['@/pages/app/wallet/walletExtractHasNewB.vue'], resolve),
        meta: {
            title: ""
        }
    }
]

const router = new VueRouter({
    mode: CommonConstants.APP_ROUTER_PATH_TYPE,
    base: process.env.BASE_URL,
    routes
})

export default router
